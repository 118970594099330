import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import ProjectService from "../services/ProjectService";

// import Cookies from 'js-cookie';

export default class Store {
  user = {};
  projects = [];
  currentProject = {};
  isAuth = false;
  isLoading = false;
  isAuthInProgress = true;
  currentFeedData = {};
  constructor() {
    makeAutoObservable(this);
  }
  setAuth(bool) {
    this.isAuth = bool;
  }
  setEndLoading(bool) {
    this.endLoading = bool;
  }
  setUser(user) {
    this.user = user;
  }
  getUser() {
    return this.user;
  }
  setProjects(projects) {
    this.projects = projects;
  }
  getStateProjects(){
    return JSON.parse(JSON.stringify(this.projects));
  }
  setCurrentProject(project) {
    this.currentProject = project;
  }


  setLoading(bool) {
    this.isLoading = bool;
  }

  async login(username, password) {
    this.isAuthInProgress = true;

    try {
     await AuthService.login(username, password).then((response)=>{

        this.authme();
        console.log(response)
      })

    } catch (e) {
      return e.response;
    } finally {
      this.isAuthInProgress = false;
    }
  }
  async authme() {
    try {
       return await AuthService.authme().then((response)=>{
        this.setAuth(true);
        this.setUser(response.data);

       })

     } catch (e) {
      //  this.setAuth(false);

       return e.response;
     }
  }
  async registration(username,email,password,confirm_password) {
    try {
      const response = await AuthService.registration(username,email,password,confirm_password);
      return response;
    } catch (e) {
      return e.response;
    } finally {
      this.isAuthInProgress = false;
    }
  }
  async uploadCsv(formData) {
    try {
      const response = await AuthService.uploadCsv(formData);
      return response;
    } catch (error) {
      console.error("Error uploading CSV:", error);
    }
  }
  async logout() {
    try {
      // eslint-disable-next-line
      const response = await AuthService.logout();
      this.setAuth(false);
      this.setUser({});
    } catch (e) {
      console.log(e.response?.data?.message);
    }
  }
  async checkAuth() {
    this.setLoading(true);
    try {
      await AuthService.refresh().then(async (response)=>{
        console.log(response);
        await this.authme().then(()=>{
          this.setLoading(false);
        })
        // this.setUser(response.data);
      });
    } catch (e) {
      console.log(e.response?.data?.message);
      this.setLoading(false);
    } finally {

      this.isAuthInProgress = false;
    }
  }
  async getProjects() {
    try {
      const response = await ProjectService.getProjects();
      this.setProjects(response.data);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addProject(name) {
    console.log(name)
    try {
      await ProjectService.addProject(name);
      this.getProjects();
      return true;
    } catch (e) {
      console.log(e);
    }
  }
  async getProject(id) {
    try {
      const response = await ProjectService.getProject(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
   updateProject = async (id, name) => {
    try {
      await ProjectService.updateProject(id, name);
      this.getProjects();
      return true;
    } catch (e) {
      console.log(e.response);
    }
  }
   deleteProject= async(id) =>{
    try {
      await ProjectService.deleteProject(id);
      this.getProjects();
      return true;
    } catch (e) {
      console.log(e.response);
    }
  }
  getFeedColumns = async(id) =>{
    try {
      const response = await ProjectService.getFeedColumns(id)
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  getFeedRows = async(id,params) =>{
    try {
      const pagenum = params.pagination.current
      const pageSize = params.pagination.pageSize
      const filters = params.filters
      const sorting = params.sorting
      const response = await ProjectService.getFeedRows(id,pagenum,pageSize,filters,sorting)
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async submitNewFeed(parent,projectName,projectMode,projectUrl,projectFile,type) {
    try {
      const response = await ProjectService.submitNewFeed(parent,projectName,projectMode,projectUrl,projectFile,type);
      this.getProjects();
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getFeed(id) {
    try {
      const response = await ProjectService.getFeed(id)
      this.setCurrentFeedData(response);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  setCurrentFeedData(response){

    this.currentFeedData = response.data;
  }
  async removeFeed(id) {
    try {
      const response = await ProjectService.removeFeed(id);
      this.getProjects();
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  updateFeed = async (id, interval, name) => {
    try {
      await ProjectService.updateFeed(id, interval, name);
      this.getProjects();
      return true;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addNewColumn(id, columnname, defaultvalue) {
    try {
      const response = await ProjectService.addNewColumn(id, columnname, defaultvalue);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addColumnWithType(tableid, columnname, attr_name, type, def) {
    console.log('addColumnWithType',tableid,columnname,attr_name, type, def)
    try {
      const response = await ProjectService.addColumnWithType(tableid,columnname,attr_name, type, def);
      return response;
    } catch (e) {
      console.log(e.response);
      return e.response;
    }
  }
  async editCell(id,value,row,cell) {
    try {
      const response = await ProjectService.editCell(id,value,row,cell);
      console.log(id,value,row,cell)
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async deleteColumn(id,column) {
    console.log(id, column)
    try {
      const response = await ProjectService.deleteColumn(id,column);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async renameColumn(id,column,newname) {
    console.log(id, column)
    try {
      const response = await ProjectService.renameColumn(id,column,newname);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getFeedErrors(id) {
    try {
      const response = await ProjectService.getFeedErrors(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getFeedOverview(id) {
    try {
      const response = await ProjectService.getFeedOverview(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async updateFeedOverview(id) {
    try {
      const response = await ProjectService.updateFeedOverview(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getFeedStructure(id) {
    try {
      const response = await ProjectService.getFeedStructure(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getFeedContent(id) {
    try {
      const response = await ProjectService.getFeedContent(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addNewFeed(id, name, newColumns, oldColumns) {
    try {
      const response = await ProjectService.addNewFeed(id, name, newColumns, oldColumns);
      console.log(123)
      console.log(response)
      this.getProjects();
      return response;
    } catch (e) {
      console.log(e.response);
      return e.response;
    }
  }
  // async setFilters(id,filtersraw) {
  //   const filters = filtersraw.map((filter) => {
  //     return {
  //       name: "Filter",
  //       connector: "AND",
  //       operator: filter.filtermethod,
  //       column: +filter.filtercolumn.replace("render+", ""),
  //       value: filter.filtervalue,
  //     };
  //   });
  //   try {
  //     const response = await ProjectService.setFilters(id,filters);
  //     return response;
  //   } catch (e) {
  //     console.log(e.response);
  //   }
  // }

  async getIntegrationsTypes() {
    try {
      const response = await ProjectService.getIntegrationsTypes();
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addIntegrations(project, name, type, data) {
    console.log('addIntegrations',project,name,type,data)
    let dataNew;
    if (type === 2 || type === "2") {
      console.log('data',data)
      dataNew = {
        url: data.api_key
      }
    } else if (type === 3 || type === "3") {
      console.log('data',data)
      dataNew = {
        token: data.api_key,
        meter_number: data.meter_number,
        period: data.period,
        ecommerce: data.ecommerce,
        metric_goals: data.metric_goals,
      }
    } else {
      dataNew = data;
    }

    try {
      console.log('dataNew',dataNew)
      const response = await ProjectService.addIntegrations(project, name, type, dataNew);
      return response;
    } catch (e) {
      return e.response;
    }
  }
  // async updateIntegration(project, name, type, data) {
  //   try {
  //     const response = await ProjectService.updateIntegration(project, name, type, data);
  //     return response;
  //   } catch (e) {
  //     return response;
  //   }
  // }
  async updateIntegration(id, name, data, type) {  let dataNew;
    if(type === 2 || type === "2") {
      console.log('data',data)
      dataNew = {
        url: data.api_key}
      }
    else {
      dataNew = data;
    }
    try {
      const response = await ProjectService.updateIntegration(id, name, dataNew);
      return response;
    } catch (e) {
      return e.response;
    }
  }
  async deleteIntegration(id) {
    try {
      const response = await ProjectService.deleteIntegration(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getIntegrations(project, type = null) {
    try {
      const response = await ProjectService.getIntegrations(project, type);
      console.log(response,project)
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async getIntegration(integration_type,integration_id) {

    try {
      let response = false;
      if(integration_type === 1){
        response = await ProjectService.getIntegrationsZoomos(integration_id);
      }
      if(integration_type === 2){
        response = await ProjectService.getIntegrationsGoogle(integration_id);
      }
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }


  //FILTERS
  async getFilters(tableid) {
    try {
      const response = await ProjectService.getFilters(tableid);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async addFilter(tableid, filter) {
    console.log('addFilter',filter);
    try {
      const response = await ProjectService.addFilter(tableid,filter);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }
  async removeFilter(tableid, filterid) {
    try {
      const response = await ProjectService.removeFilter(tableid, filterid);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }

  //XLSX
  async downloadFeedXlsx(tableid) {
    console.log('downloadFeedXlsx',tableid);
    try {
      const response = await ProjectService.downloadFeedXlsx(tableid);
      return response;
    } catch (e) {
      console.log(e.response);
      return e.response;
    }
  }

  async getFeedTypes() {
    try {
      const response = await ProjectService.getFeedTypes();
      return response;
    } catch (e) {
      console.log(e.response);
      return e.response;
    }
  }

  //CHARTS
  async getFeedChartOverview(id) {
    try {
      const response = await ProjectService.getFeedChartOverview(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }

  async addFilterFeedChart(id, date_to, date_from) {
    try {
      const response = await ProjectService.addFilterFeedChart(id, date_to, date_from);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }

  //PARENTS FEED
  async getParentFeed(id) {
    try {
      const response = await ProjectService.getParentFeed(id);
      return response;
    } catch (e) {
      console.log(e.response);
    }
  }

  async rewriteParentFeed(id, newColumns, oldColumns) {
    try {
      const response = await ProjectService.rewriteParentFeed(id, newColumns, oldColumns);
      console.log(123)
      console.log(response)
      this.getProjects();
      return response;
    } catch (e) {
      console.log(e.response);
      return e.response;
    }
  }

}
