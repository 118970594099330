import React, { useEffect } from "react";
import {Button, Modal, Form, Input, Select, Checkbox, Steps} from "antd";
import DeleteModal from "../ui/Modals/DeleteModal";
import AddProjectTableMainFields from "../Layout/Sidebar/AddProject/AddProjectTableMainFields";
import AddProjectTableFile from "../Layout/Sidebar/AddProject/AddProjectTableFile";

const ModalsIntegration = ({
  setPopoverVisible,
  setModalsVisible,
  modalsVisible,
  addIntegration,
  updateIntegration,
  deleteIntegration,
  integrationid,
  updateIntegrations,
  clickedRow,
  currentIntegration,
  setCurrentIntegration
}) => {
  const [form] = Form.useForm(); // Инициализация формы
  const [formEdit] = Form.useForm(); // Инициализация формы
  const [days, setDays] = React.useState();
  const [ecommerce, setEcommerce] = React.useState(false);

  // Эффект для обновления формы при изменении clickedRow
  useEffect(() => {
    console.log(clickedRow);
    if (modalsVisible.editModalVisible) {
      // Обновляем значения формы, когда модальное окно становится видимым и clickedRow изменяется
      formEdit.setFieldsValue({
        name: clickedRow?.name,
        token: clickedRow?.data.api_key,
        feedUrl: clickedRow?.data.zoomos_feed,
        url: clickedRow?.data.url,
        meterNumber: clickedRow?.data.meter_number,
        period: clickedRow?.data.period,
        ecommerce: clickedRow?.data.ecommerce,
        metricGoals: clickedRow?.data.metric_goals,
      });
    }
  }, [clickedRow, modalsVisible.editModalVisible, formEdit, days]);

  const handleEditModalOk = (e) => {
    e.stopPropagation();
    const checkform = formEdit.getFieldsValue();

    // Функция для приведения значений к общему виду (замена null на пустую строку)
    const normalize = (value) => value === null ? '' : value;

    // Приводим значения к общему виду
    const formData = {
      name: normalize(checkform.name),
      token: normalize(checkform.token),
      feedUrl: normalize(checkform.feedUrl),
      url: normalize(checkform.url),
      meterNumber: normalize(checkform.meterNumber),
      period: normalize(checkform.period),
      ecommerce: normalize(checkform.ecommerce),
      metricGoals: normalize(checkform.metricGoals)
    };

    const originalData = {
      name: normalize(clickedRow?.name),
      token: normalize(clickedRow?.data.api_key),
      feedUrl: normalize(clickedRow?.data.zoomos_feed),
      url: normalize(clickedRow?.data.url),
      meterNumber: normalize(clickedRow?.data.meter_number),
      period: normalize(clickedRow?.data.period),
      ecommerce: normalize(clickedRow?.data.ecommerce),
      metricGoals: normalize(clickedRow?.data.metric_goals)
    };

    let shouldSubmit = false;

    if (clickedRow?.type === 1) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.token !== formData.token ||
                     originalData.feedUrl !== formData.feedUrl;
    }

    if (clickedRow?.type === 2) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.url !== formData.url;
    }

    if (clickedRow?.type === 3) {
      shouldSubmit = originalData.name !== formData.name ||
                     originalData.meterNumber !== formData.meterNumber ||
                     originalData.period !== formData.period ||
                     originalData.ecommerce !== formData.ecommerce ||
                     originalData.metricGoals !== formData.metricGoals;
    }

    if (shouldSubmit) {
      // console.log('submit');
      formEdit.submit(); // Сабмит формы
    }
    else{
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
    }
  };


  const handleEditModalCancel = (e) => {
    e.stopPropagation();
    formEdit.resetFields();
    setModalsVisible((prev) => ({
      ...prev,
      editModalVisible: false,
    }));
  };

  const handleDeleteModalOk = async () => {
    // Здесь может быть логика удаления элемента
    await deleteIntegration(clickedRow.key).then(() => {
      setModalsVisible((prev) => ({
        ...prev,
        deleteModalVisible: false,
      }));
      updateIntegrations();
    });
  };

  const setDeleteModalVisible = (state) => {
    setModalsVisible((prev) => ({
      ...prev,
      deleteModalVisible: state,
    }));
  };

  const handleOk = () => {
    form.submit(); // Сабмит формы

  };

  const handleCancel = () => {
    setCurrentIntegration(null)
    setModalsVisible((prev) => ({
      ...prev,
      isModalVisible: false,
    }));

    form.resetFields();
  };

  const onFinish = async (values) => {
    const data = {
      api_key: values.token,
      zoomos_feed: values.feedUrl || null,
      meter_number: values.meterNumber || null,
      period: values.period || null,
      ecommerce: ecommerce || null,
      metric_goals: values.metricGoals || null,
    };

    const response = await addIntegration(integrationid, values.name, currentIntegration, data);
    if (response.status === 201) {
      setModalsVisible((prev) => ({
        ...prev,
        isModalVisible: false,
      }));
      updateIntegrations();
      form.resetFields();
      setCurrentIntegration(null)
    } else if (response.status === 422) {
      const currentFields = form.getFieldsValue();
      console.log(currentFields);
      form.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          //errors: ['Такая интеграция уже добавлена!'],
          errors: [response.data.detail],
        },
      ]);
    }
  };

  const onFinishEdit = async (values) => {
    let data = {}
    if(currentIntegration === "1"){
      data = {
        api_key: values.token,
        zoomos_feed: values.feedUrl || null,
      };
    }else if (currentIntegration === "3") {
      data = {
        api_key: values.token,
        meter_number: values.meterNumber || null,
        period: values.period || null,
        ecommerce: values.ecommerce || null,
        metric_goals: values.metricGoals || null,
      };
    }else{
      data = {
        url: values.url,
      };
    }

    const response = await updateIntegration(clickedRow.key, values.name, data, currentIntegration);

    if (response.status === 200) {
      setModalsVisible((prev) => ({
        ...prev,
        editModalVisible: false,
      }));
      updateIntegrations();
      formEdit.resetFields();
    } else if (response.status === 422) {
      const currentFields = formEdit.getFieldsValue();
      formEdit.setFields([
        ...Object.keys(currentFields).map(key => ({
          name: key,
          value: currentFields[key]
        })),
        {
          name: 'token',
          errors: ['Такая интеграция уже добавлена!'],
        },
      ]);
    }
  };

  const onChangeEcommerce = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    setEcommerce(e.target.checked);
  };

  // const steps = [
  //   {
  //     title: "Настройка",
  //     content: (
  //         <AddProjectTableMainFields
  //             selectedFeedType={selectedFeedType}
  //             setSelectedFeedType={setSelectedFeedType}
  //             feedTypes={feedTypes}
  //             handleSelectChange={handleSelectChange}
  //             modeVals={[
  //               { label: "Необновляемый источник", value: 1 },
  //               { label: "Обновляемый источник", value: 2 },
  //             ]}
  //             selectValue={projectMode}
  //             handleNameChange={handleNameChange}
  //             projectName={projectName}
  //         />
  //     ),
  //   },
  //   {
  //     title: "Загрузка",
  //     content: (
  //         <AddProjectTableFile
  //             mode={projectMode}
  //             projectUrl={projectUrl}
  //             fileChange={handleFileChange}
  //             urlChange={handleUrlChange}
  //             feedType={selectedFeedType}
  //         />
  //     ),
  //   },
  //   // {
  //   //   title: "Готово",
  //   //   content: "Last-content",
  //   // },
  // ];
  // const items = steps.map((item) => ({
  //   key: item.title,
  //   title: item.title,
  // }));


  return (
    <>

      {/*<div className="stepsWrapper">*/}
      {/*  <Steps current={current} items={items} />*/}

      {/*  <div>{steps[current].content} </div>*/}

      {/*  <div className="stepsBtn">*/}
      {/*    {current === 0 && projectName !== "" && projectMode !== "" && selectedFeedType !== "" && (*/}
      {/*        <Button type="primary" onClick={() => next()}>*/}
      {/*          Дальше*/}
      {/*        </Button>*/}
      {/*    )}*/}
      {/*    {current > 0 && (*/}
      {/*        <Button*/}
      {/*            style={{*/}
      {/*              margin: "0 8px",*/}
      {/*            }}*/}
      {/*            onClick={() => prev()}*/}
      {/*        >*/}
      {/*          Назад*/}
      {/*        </Button>*/}
      {/*    )}*/}
      {/*    {current === steps.length - 1 &&*/}
      {/*    projectName !== "" &&*/}
      {/*    (projectUrl !== "" || projectFile != null) && (*/}
      {/*        <Button*/}
      {/*            type="primary"*/}
      {/*            loading={isLoading}*/}
      {/*            onClick={() => {*/}
      {/*              submitNewFeed();*/}
      {/*            }}*/}
      {/*        >*/}
      {/*          Добавить*/}
      {/*        </Button>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}

      <Modal
        title="Добавить интеграцию"
        open={modalsVisible.isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Сохранить
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="integrationForm"
          onFinish={onFinish}
          initialValues={{ remember: false }}
          autoComplete="off"
          style={{ marginTop: 20 }}
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название интеграции!",
              },
            ]}
          >
            <Input placeholder="Название интеграции" />
          </Form.Item>
          <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: currentIntegration === "1" ? "Пожалуйста, введите API токен аккаунта!" : currentIntegration === "3" ? "Пожалуйста, введите токен Яндекс Метрики" : "Пожалуйста, введите ссылку на публичную таблицу в Google Sheets",
                },
              ]}
          >
            <Input
                placeholder={currentIntegration === "1" ? "API токен аккаунта" : currentIntegration === "3" ? "Токен Яндекс Метрики" : "Ссылка на публичную таблицу в Google Sheets"}/>
          </Form.Item>
          {currentIntegration === "3" &&
            <span>
              - Залогиньтесь в аккаунт, у которого есть доступ к нужному вам счетчику метрики<br />
              - Перейдите по ссылке: https://oauth.yandex.ru/authorize?response type=token&client id=dbb281abcd134b1bb4c624748f03cffe<br />
              - Полученный токен вставьте в поле "Токен Яндекс Метрики"<br /><br />
            </span>
          }
          {currentIntegration === "1" && <Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos" />
          </Form.Item>}

          {currentIntegration === "3" &&
            <Form.Item
                name="meterNumber"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, введите номер счетчика!",
                  },
                ]}>
              <Input placeholder="Номер счетчика Яндекс Метрики" />
            </Form.Item>
          }
          {/*{currentIntegration === "3" &&*/}
          {/*  <Form.Item*/}
          {/*      name="metricToken"*/}
          {/*      rules={[*/}
          {/*        {*/}
          {/*          required: true,*/}
          {/*          message: "Пожалуйста, введите токен Яндекс Метрики!",*/}
          {/*        },*/}
          {/*      ]}>*/}
          {/*    <Input placeholder="Токен Яндекс Метрики" />*/}
          {/*  </Form.Item>*/}
          {/*}*/}
          {/*{currentIntegration === "3" &&*/}
          {/*    <span>*/}
          {/*      - Залогиньтесь в аккаунт, у которого есть доступ к нужному вам счетчику метрики<br />*/}
          {/*      - Перейдите по ссылке: https://oauth.yandex.ru/authorize?response type=token&client id=dbb281abcd134b1bb4c624748f03cffe<br />*/}
          {/*      - Полученный токен вставьте в поле ниже<br /><br />*/}
          {/*    </span>*/}
          {/*}*/}
          {currentIntegration === "3" &&
            <Form.Item
                name="period"
                rules={[
                  {
                    required: true,
                    message: "Пожалуйста, выберите период первичных данных!",
                  },
                ]}>
              <Select
                  style={{ width: 300 }}
                  placeholder="Выберите период первичных данных"
                  onChange={(e) => setDays(e)}
                  value={days}
                  options={[{label:"последние 30 дней",value:30},{label:"последние 90 дней",value:90},{label:"последние 180 дней",value:180},{label:"последние 365 дней",value:365}]}
              />
            </Form.Item>
          }
          {/*{currentIntegration === "3" &&*/}
          {/*  <Form.Item name="ecommerce">*/}
          {/*    <Checkbox*/}
          {/*        onChange={onChangeEcommerce}*/}
          {/*    >*/}
          {/*      Ecommerce на сайте*/}
          {/*    </Checkbox>*/}
          {/*  </Form.Item>*/}
          {/*}*/}
          {/*{currentIntegration === "3" &&*/}
          {/*  <Form.Item name="metricGoals">*/}
          {/*    <Input placeholder="Список целей метрики (через запятую)" />*/}
          {/*  </Form.Item>*/}
          {/*}*/}

        </Form>
      </Modal>
      <Modal
        title="Редактировать"
        open={modalsVisible.editModalVisible}
        onOk={handleEditModalOk}
        onCancel={handleEditModalCancel}
        footer={[
          <Button key="back" onClick={handleEditModalCancel}>
            Отмена
          </Button>,
          <Button key="submit" type="primary" onClick={handleEditModalOk}>
            Сохранить изменения
          </Button>,
        ]}
      >
        <Form
          form={formEdit}
          name="editIntegrationForm"
          onFinish={onFinishEdit}
          initialValues={{ remember: true }}
          autoComplete="off"
          style={{ marginTop: 20 }}
        >

          <Form.Item
            name="name"
            shouldUpdate
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите название интеграции!",
              },
            ]}
          >
            <Input placeholder="Название интеграции" />
          </Form.Item>

          {clickedRow?.type === 1 &&  <> <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите API токен аккаунта!",
              },
            ]}
          >
            <Input placeholder={clickedRow.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item><Form.Item name="feedUrl">
            <Input placeholder="Ссылка на фид, используемый в zoomos" />
          </Form.Item></>}
          {clickedRow?.type === 2 &&  <> <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите URL!",
              },
            ]}
          >
            <Input placeholder={clickedRow?.type === 1?"API токен аккаунта":"Ссылка на публичную таблицу в Google Sheets"} />
          </Form.Item></>}
        </Form>
      </Modal>

      <DeleteModal
        onConfirmDelete={handleDeleteModalOk}
        isModalDeleteOpen={modalsVisible.deleteModalVisible}
        setIsModalDeleteOpen={setDeleteModalVisible}
        title="Удалить интеграцию"
        text={`Вы уверены, что хотите удалить "${clickedRow?.name}" со всем содержимым?`}
      />
    </>
  );
};

export default ModalsIntegration;
