import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Context } from "..";
import { Breadcrumb, Row, Col, Table, Button, Popover, Flex, Modal, Select } from "antd";
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';  // Для локализации на русский язык
import {
  HomeOutlined,
  SettingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ModalsIntegration from "../components/Integrations/ModalsIntegration";
import ErrorModal from "../components/ui/Modals/ErrorModal";

const ProjectIntegrationsPage = ({ handleOpenChange, setOpenMenuItem }) => {
  const { store } = useContext(Context);
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [clickedRow, setClickedRow] = useState(null);
  const [modalsVisible, setModalsVisible] = useState({
    isModalVisible: false,
    editModalVisible: false,
    deleteModalVisible: false,
    selectIntegrationModalVisible: false,
  });

 const [currentIntegration, setCurrentIntegration] = useState(null);
  const showModal = () => {

    setModalsVisible((prev) => {
      return {
        ...prev,
        selectIntegrationModalVisible: true,
      };
    });
  };
  const handleIntegrationSelectOk = (e) => {
    setCurrentIntegration(null)
    setModalsVisible((prev) => {
      return {
        ...prev,
        selectIntegrationModalVisible: false,
      };
    });
  };
  const handleIntegrationSelectCancel = () => {
    setCurrentIntegration(null)
    setModalsVisible((prev) => {
      return {
        ...prev,
        selectIntegrationModalVisible: false,
      };
    });
  };
 const setCurrentIntegrationModal = (e) => {
  setCurrentIntegration(e)
    console.log(e)
    setModalsVisible((prev) => {
      return {
        ...prev,
        isModalVisible: true,
        selectIntegrationModalVisible: false,
      };
    });
  };
  const handleMoreBtnClick = (e,el) => {
    e.stopPropagation();
    // setPopoverVisible(!popoverVisible);
    console.log(el,e, 'e')
    setClickedRow({...el, popoverVisible: true});
  };
  const setPopoverVisible = (state) => {

    if(clickedRow?.popoverVisible){
      setClickedRow((prev)=>{
        return {
          ...prev,
          popoverVisible: state
        }
      })
    }
  };



  const handleIntegrationEdit = (e) => {
    e.stopPropagation();
    setModalsVisible((prev) => {
      return {
        ...prev,
        editModalVisible: true,
      };
    });
    setPopoverVisible(false);
  };
  const handleIntegrationDelete = (e) => {
    e.stopPropagation();
    setModalsVisible((prev) => {
      return {
        ...prev,
        deleteModalVisible: true,
      };
    });
    setPopoverVisible(false);
  };

  const handlePopoverClose = () => {
    // setPopoverVisible(false);
    if(clickedRow?.popoverVisible){
      setClickedRow((prev)=>{
        return {
          ...prev,
          popoverVisible: false
        }
      })
    }
  };

  const prepareData = (data) => {
    console.log(data)
    return data.map((row) => {
      return {
        key: row.id,
        data: row.data,
        name: row.name,
        type: row.type,
        update: [row.updated_at,row.created_at],
        status: row.status,
        infeeds: row.feeds.map((feed, index) => {
            return (
                <span key={index}>{ (index ? ', ' : '') }<a href={`/feeds/${feed.id}`}>{feed.name}</a></span>
            );
        }),
      };
    });
  };

  const updateIntegrations = async () => {
    store.getIntegrations(id).then((response) => {
      setData(prepareData(response.data));
    });
  };

  useEffect( () => {
    const fetchProject = async () => {
      try {
        await store.getProject(id).then((project) => {
          if (!project) {
            navigate("/404");
          }
          // console.log(project.data.id)
          setProjectName(project.data.name);
          handleOpenChange([`parent_${project.data.id}`]);
          updateIntegrations();
        });
      } catch (error) {
        console.error("Error fetching feed:", error);
        navigate("/404");
      }
    };
    fetchProject();
    // eslint-disable-next-line
  }, [id]);


 const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

  const content = (
    <ul className="sidebar_parentmenu-list">
      <li onClick={handleIntegrationEdit}>
        <EditOutlined />
        <span>Переименовать</span>
      </li>
      <li onClick={handleIntegrationDelete}>
        <DeleteOutlined />
        <span>Удалить</span>
      </li>
    </ul>
  );
  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      editable: true,
      render: (text,el) => {
        return (
          <>
            <Popover
              open={clickedRow?.key === el.key?clickedRow.popoverVisible:false}
              onOpenChange={handlePopoverClose}
              placement="right"
              content={content}
              trigger="click"
            >
              <SettingOutlined
                onClick={(e)=>handleMoreBtnClick(e,el)}
                style={{ marginRight: 30 }}
              />
            </Popover>
            {text}
          </>
        );
      },
    },
    {
      title: "Тип интеграции",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (text) => {
        switch (text) {
          case 1:
            return <Flex align="center" gap={10} ><img src="/zoomos.png" style={{width: 20}} alt="zoomos" /> Zoomos</Flex>;
          case 2:
             return <Flex align="center" gap={10} ><img src="/gsheets.png" style={{width: 20}} alt="gsheets" /> Google Sheets</Flex>;
          case 3:
             return <Flex align="center" gap={10} ><img src="/ym.png" style={{width: 20}} alt="ym" /> Яндекс Метрика</Flex>;
          default:
            return "Неизвестно";
        }
      }
    },
    {
      title: "Дата обновления",
      dataIndex: "update",
      key: "update",
      render: (text) => {
        let formattedDate = '';
        if(text[0]){
          formattedDate = text[0] ? format(parseISO(text[0]), 'dd MMMM yyyy, HH:mm', { locale: ru }) : '';

        }
        else{
          formattedDate = text[1] ? format(parseISO(text[1]), 'dd MMMM yyyy, HH:mm', { locale: ru }) : '';
        }
        return formattedDate;
      }

    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let text = "Подготовка"
        if(status === 1){
          text = (<div className="integrationstatus"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM10.0234 4.71406L6.73281 9.27656C6.68682 9.34076 6.62619 9.39306 6.55595 9.42914C6.48571 9.46523 6.40787 9.48405 6.32891 9.48405C6.24994 9.48405 6.17211 9.46523 6.10186 9.42914C6.03162 9.39306 5.97099 9.34076 5.925 9.27656L3.97656 6.57656C3.91719 6.49375 3.97656 6.37813 4.07812 6.37813H4.81094C4.97031 6.37813 5.12187 6.45469 5.21562 6.58594L6.32812 8.12969L8.78438 4.72344C8.87813 4.59375 9.02812 4.51562 9.18906 4.51562H9.92188C10.0234 4.51562 10.0828 4.63125 10.0234 4.71406Z" fill="#04A213" fillOpacity="0.85"/>
          </svg>
           Успешно</div>)
        }
        else if(status === 0){
          text = (<div className="integrationstatus"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.5007 11.9813C12.0217 10.7549 13 8.84483 13 6.70022C13 2.9991 10.0994 0.00503272 6.50975 6.30538e-06C2.91525 -0.00502011 0 2.99575 0 6.70022C0 8.79791 0.934375 10.6694 2.3985 11.8975C2.45537 11.9445 2.53825 11.9344 2.58375 11.8758L3.224 11.0296C3.26787 10.9727 3.25813 10.8906 3.2045 10.8437C3.07288 10.7331 2.94612 10.6141 2.82425 10.4885C2.34913 10.0003 1.97053 9.42158 1.7095 8.78451C1.4365 8.12605 1.3 7.42403 1.3 6.70022C1.3 5.97642 1.4365 5.27439 1.70788 4.61426C1.9695 3.9759 2.34488 3.40289 2.82263 2.9103C3.30038 2.41771 3.85612 2.03068 4.47525 1.76093C5.11712 1.48112 5.798 1.34038 6.5 1.34038C7.202 1.34038 7.88287 1.48112 8.52312 1.76093C9.14225 2.03068 9.698 2.41771 10.1757 2.9103C10.6535 3.40289 11.0289 3.9759 11.2905 4.61426C11.5619 5.27439 11.6984 5.97642 11.6984 6.70022C11.6984 7.42403 11.5619 8.12605 11.2905 8.78619C11.0295 9.42326 10.6509 10.002 10.1757 10.4901C10.0246 10.646 9.86537 10.7917 9.69963 10.9258L9.03825 10.0528C9.02328 10.0329 9.00314 10.0177 8.98017 10.009C8.95719 10.0003 8.93231 9.9985 8.90837 10.0038C8.88444 10.009 8.86242 10.0211 8.84487 10.0387C8.82731 10.0563 8.81492 10.0786 8.80912 10.1031L8.16562 12.8207C8.14612 12.9045 8.20787 12.9866 8.29075 12.9866L11.0045 13C11.1134 13 11.1751 12.871 11.1069 12.7839L10.5007 11.9813Z" fill="#5B9BFF"/>
          </svg>

           В обработке</div>)
        }
        else if(status === -1){
          text = (<div className="integrationstatus"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z" fill="#E2C00D"/>
          <path d="M7.25 10.75C7.25 10.9489 7.32902 11.1397 7.46967 11.2803C7.61032 11.421 7.80109 11.5 8 11.5C8.19891 11.5 8.38968 11.421 8.53033 11.2803C8.67098 11.1397 8.75 10.9489 8.75 10.75C8.75 10.5511 8.67098 10.3603 8.53033 10.2197C8.38968 10.079 8.19891 10 8 10C7.80109 10 7.61032 10.079 7.46967 10.2197C7.32902 10.3603 7.25 10.5511 7.25 10.75ZM7.625 9H8.375C8.44375 9 8.5 8.94375 8.5 8.875V4.625C8.5 4.55625 8.44375 4.5 8.375 4.5H7.625C7.55625 4.5 7.5 4.55625 7.5 4.625V8.875C7.5 8.94375 7.55625 9 7.625 9Z" fill="#E2C00D"/>
          </svg>

           Есть проблемы
              <div onClick={()=>setIsModalErrorOpen(true)}><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0C2.23884 0 0 2.23884 0 5C0 7.76116 2.23884 10 5 10C7.76116 10 10 7.76116 10 5C10 2.23884 7.76116 0 5 0ZM5 9.15179C2.70759 9.15179 0.848214 7.29241 0.848214 5C0.848214 2.70759 2.70759 0.848214 5 0.848214C7.29241 0.848214 9.15179 2.70759 9.15179 5C9.15179 7.29241 7.29241 9.15179 5 9.15179Z" fill="#7A7A7A"/>
                <path d="M6.24219 2.81996C5.90737 2.52644 5.4654 2.36572 4.99665 2.36572C4.5279 2.36572 4.08594 2.52755 3.75112 2.81996C3.4029 3.12465 3.21094 3.53425 3.21094 3.97287V4.05769C3.21094 4.10679 3.25112 4.14697 3.30022 4.14697H3.83594C3.88504 4.14697 3.92522 4.10679 3.92522 4.05769V3.97287C3.92522 3.48068 4.40625 3.08001 4.99665 3.08001C5.58705 3.08001 6.06808 3.48068 6.06808 3.97287C6.06808 4.31996 5.82255 4.63804 5.44196 4.78425C5.20536 4.87465 5.00446 5.03313 4.86049 5.24072C4.71429 5.45278 4.63839 5.70724 4.63839 5.96505V6.20501C4.63839 6.25412 4.67857 6.29429 4.72768 6.29429H5.26339C5.3125 6.29429 5.35268 6.25412 5.35268 6.20501V5.95166C5.35326 5.84331 5.38647 5.73766 5.44799 5.64846C5.5095 5.55927 5.59647 5.49069 5.69754 5.45166C6.35603 5.19831 6.78125 4.61795 6.78125 3.97287C6.78237 3.53425 6.5904 3.12465 6.24219 2.81996ZM4.55022 7.45501C4.55022 7.57341 4.59726 7.68696 4.68098 7.77068C4.7647 7.8544 4.87825 7.90144 4.99665 7.90144C5.11505 7.90144 5.2286 7.8544 5.31232 7.77068C5.39605 7.68696 5.44308 7.57341 5.44308 7.45501C5.44308 7.33661 5.39605 7.22306 5.31232 7.13934C5.2286 7.05561 5.11505 7.00858 4.99665 7.00858C4.87825 7.00858 4.7647 7.05561 4.68098 7.13934C4.59726 7.22306 4.55022 7.33661 4.55022 7.45501Z" fill="#7A7A7A"/>
                </svg>
              </div>
          </div>)
        }
        return text;


      }
    },
    {
      title: "В фидах",
      dataIndex: "infeeds",
      key: "infeeds",
    },
  ];
  // const components = {
  //   body: {
  //     cell: IntegrationsCell,
  //     row: IntegrationsRow,
  //   },
  // };


  return (
    <>

      <Row>
        <Col span={10}>
          <Breadcrumb
            items={[
              {
                title: <HomeOutlined />,
              },
              {
                title: projectName,
              },
              {
                title: "Интеграции",
              },
            ]}
          />
        </Col>
      </Row>
      <h1>Интеграции проекта</h1>
      <Button
        type="primary"
        className="btn"
        onClick={showModal}
        style={{ margin: "30px 0" }}
      >
        Добавить интеграцию
      </Button>
      <Modal
        title="Добавить интеграцию"
        open={modalsVisible.selectIntegrationModalVisible}
        onOk={handleIntegrationSelectOk}
        onCancel={handleIntegrationSelectCancel}
        footer={null}
      >
        <Select
          style={{ width: 300 }}
          placeholder="Выберите интеграции"
          onChange={(e) => setCurrentIntegrationModal(e)}
          value={currentIntegration}
          options={[{label:"Zoomos",value:"1"},{label:"Google Sheets",value:"2"},{label:"Яндекс Метрика",value:"3"}]}
        >

        </Select>
      </Modal>
      {/*{currentIntegration}*/}
      <Table
      // components={components}
      dataSource={data} columns={columns} bordered />

      <ModalsIntegration
        currentIntegration={currentIntegration}
        setCurrentIntegration={setCurrentIntegration}
        modalsVisible={modalsVisible}
        setModalsVisible={setModalsVisible}
        setPopoverVisible={setPopoverVisible}
        addIntegration={store.addIntegrations}
        updateIntegration={store.updateIntegration}
        deleteIntegration={store.deleteIntegration}
        integrationid={id}
        clickedRow={clickedRow}
        updateIntegrations={updateIntegrations}
      />

      <ErrorModal
      isModalErrorOpen={isModalErrorOpen}
      setIsModalErrorOpen={setIsModalErrorOpen}
      btnText="Хорошо"
      text="Не удалось настроить интеграцию, попробуйте снова"
      />
      </>
  );
};

export default ProjectIntegrationsPage;
