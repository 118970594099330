import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button, Table, Flex, Col } from "antd";

const averageColumns = [
    {
        label: "ym:s:users",
        value: "ym_s_users",
    },
    {
        label: "ym:s:visits",
        value: "ym_s_visits",
    },
    {
        label: "ym:s:pageviews",
        value: "ym_s_pageviews",
    },
];

const YandexForm = ({
                        integrationSelected,
                        feedColumns,
                        getIntegration,
                        integration_type,
                        integration_id,
                        errTitle,
                        clearErrTitle,
                        tablerows,
                        closeModal,
                        selectColumns,
                        submitModal}) => {
    const [form] = Form.useForm();
    const [preparedIntegrationColumns, setPreparedIntegrationColumns] = useState(
        []
    );
    // eslint-disable-next-line
    const [preparedFeedColumns, setPreparedFeedColumns] = useState([]);
    const [preparedFeedTableColumns, setPreparedTableFeedColumns] = useState([]);

    // const [preparedTableIntegrationColumns, setTableIntegrationColumns] =    useState([]);
    // const [preparedTableIntegrationRows, setTableIntegrationRows] = useState([]);

    const prepareIntegrationColumns = (columns) => {
        console.log(columns)
        return columns.map((col) => {
            return {
                value: col,
                label: col,
            };
        });
    };

    const prepareFeedColumns = (columns) => {

        return columns.map((col) => {
            return {
                value: col.dataIndex,
                label: col.ctitle,
            };
        });
    };

    const prepareTableFeedColumns = (columns) => {
        return columns.map((col) => {
            return {
                ...col,
                title: col.ctitle,
                width: 150,
                ellipsis: true,
            };
        });
    };

    useEffect(() => {
        const fetchIntegrationsYM = async () => {
            try {
                const integration = await getIntegration(
                    integration_type,
                    integration_id
                );
                console.log(integration,'integration',integration_type,
                    integration_id);
                setPreparedIntegrationColumns(
                    prepareIntegrationColumns(integration.data.columns)
                );
            } catch (error) {
                console.error("Error fetching feed columns:", error);
            }
        };
        if (integrationSelected) {
            fetchIntegrationsYM();
        }
    }, [integrationSelected, getIntegration, integration_type, integration_id]);

    useEffect(() => {
        setPreparedFeedColumns(prepareFeedColumns(feedColumns));
        setPreparedTableFeedColumns(prepareTableFeedColumns(feedColumns));
    }, [feedColumns]);

    const handleSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                submitModal(values, integration_id, integration_type);
                // closeModal();
                // Здесь можно добавить код для отправки данных формы
            })
            .catch((errorInfo) => {
                console.error("Failed:", errorInfo);
            });
    };

    const handleReset = () => {
        form.resetFields();
        closeModal();
    };

    const filterOption = (input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <h3>Добавление данных из Я.Метрики</h3>
            {/*<h3>Текущий фид</h3>*/}
            {/*<Table*/}
            {/*    style={{ marginTop: 15, marginBottom: 30 }}*/}
            {/*    pagination={false}*/}
            {/*    dataSource={tablerows.slice(0, 1)}*/}
            {/*    columns={preparedFeedTableColumns}*/}
            {/*    bordered*/}
            {/*    scroll={{ x: preparedFeedTableColumns.length * 150 }}*/}
            {/*/>*/}

            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {/*<Form.Item*/}
                {/*    label="Выбрать столбец в текущем фиде"*/}
                {/*    name="in_match_column"*/}
                {/*    rules={[*/}
                {/*        { required: true, message: "Нужно выбрать столбец в текущем фиде" },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <Select*/}
                {/*        options={selectColumns}*/}
                {/*        showSearch*/}
                {/*        optionFilterProp="children"*/}
                {/*        filterOption={filterOption}*/}
                {/*        placeholder="Выберите столбец"*/}
                {/*    />*/}
                {/*</Form.Item>*/}

                <Flex align="">
                    <Col span={12}>
                        {" "}
                        <Form.Item
                            label="Название тега"
                            name="title"
                            onChange={clearErrTitle}
                            rules={[{ required: true, message: "Введите название тега" }]}
                        >
                            <Input placeholder="Введите название тега" />
                        </Form.Item>
                        {errTitle && <div style={{ color: "red", marginTop: -20,marginBottom: 20 }}>{errTitle}</div>}
                    </Col>
                </Flex>
                <Flex align="">
                    <Col span={12}>
                        {" "}
                        <Form.Item
                            label="Выберите показатель для добавления в фид"
                            name="aggregation"
                            rules={[
                                { required: true, message: "Нужно выбрать показатель" },
                            ]}
                        >
                            <Select
                                options={averageColumns}
                                placeholder="Выберите показатель"
                            />
                        </Form.Item>
                    </Col>
                </Flex>
                <Flex align="">
                    <Col span={12}>
                        {" "}
                        <Form.Item
                            label="Количество дней, за которые делать расчет"
                            name="period"
                            onChange={clearErrTitle}
                            rules={[{ required: true, message: "Укажите количество дней" }]}
                        >
                            <Input placeholder="Введите количество дней" />
                        </Form.Item>
                        {errTitle && <div style={{ color: "red", marginTop: -20,marginBottom: 20 }}>{errTitle}</div>}
                    </Col>
                </Flex>

                {/*<Flex align="">*/}
                {/*    <Col span={11}>*/}
                {/*        <Form.Item*/}
                {/*            label="Выберите столбец сопоставления из Google Таблицы "*/}
                {/*            name="google_match_column"*/}
                {/*            rules={[*/}
                {/*                { required: true, message: "Выберите столбец из Google Таблицы" },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Select*/}
                {/*                options={preparedIntegrationColumns}*/}
                {/*                showSearch*/}
                {/*                optionFilterProp="children"*/}
                {/*                filterOption={filterOption}*/}
                {/*                placeholder="Выберите столбец "*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*    </Col>{" "}*/}
                {/*    <Col span={2}></Col>*/}
                {/*    <Col span={11}>*/}
                {/*        <Form.Item*/}
                {/*            label="Добавить в фид значения столбца из Google Таблицы "*/}
                {/*            name="google_value_column"*/}
                {/*            rules={[*/}
                {/*                { required: true, message: "Выберите столбец из Google Таблицы" },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Select*/}
                {/*                showSearch*/}
                {/*                optionFilterProp="children"*/}
                {/*                filterOption={filterOption}*/}
                {/*                options={preparedIntegrationColumns}*/}
                {/*                placeholder="Выберите столбец "*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Flex>*/}

                {/*<Flex align="">*/}
                {/*    <Col span={11}>*/}
                {/*        {" "}*/}
                {/*        <Form.Item*/}
                {/*            label="Агрегировать получаемое значение"*/}
                {/*            name="aggregation"*/}
                {/*            rules={[*/}
                {/*                { required: true, message: "Нужно выбрать тип агригации" },*/}
                {/*            ]}*/}
                {/*        >*/}
                {/*            <Select*/}
                {/*                options={averageColumns}*/}
                {/*                placeholder="Выберите тип интеграции"*/}
                {/*            />*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*    <Col span={2}></Col>*/}
                {/*    <Col span={11}>*/}
                {/*        {" "}*/}
                {/*        <Form.Item*/}
                {/*            label="Название для нового столбца"*/}
                {/*            name="title"*/}
                {/*            onChange={clearErrTitle}*/}
                {/*            rules={[{ required: true, message: "Введите название" }]}*/}
                {/*        >*/}
                {/*            <Input placeholder="Введите название " />*/}
                {/*        </Form.Item>*/}
                {/*        {errTitle && <div style={{ color: "red", marginTop: -20,marginBottom: 20 }}>{errTitle}</div>}*/}
                {/*    </Col>*/}
                {/*</Flex>*/}

                <Form.Item>
                    <Flex justify="flex-end">
                        <Button
                            size="large"
                            className="btn"
                            style={{ margin: "0 8px" }}
                            onClick={handleReset}
                        >
                            Отмена
                        </Button>
                        <Button size="large" type="primary" htmlType="submit" style={{height: 50}}>
                            Добавить
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </>
    );
};

export default YandexForm;
